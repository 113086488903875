<template>
  <div :class="{ contain: true, mobile: isMobile }">
      <div class="setting d-flex flex-row">
          <div class="left">
              <div class="title" :class="{clickedItem:isClickedSet}" @click="clicked('mySetting')"> {{$t('notification_settings')}}</div>
              <div class="title mt-5" :class="{clickedItem:isHield}" @click="clicked('myShield')">{{$t('Hidden_list')}}</div>
          </div>
          <div class="right ml-10 " v-if="selectModel == 'mySetting'">
              <div class="setTitle">{{ $t("notification_settings") }}</div>
          <div class="description mt-lg-5">{{ $t("setting_des") }} <span>{{address}}</span></div>
          <div class="list mt-7">
                <div class="settingList" v-for="(item, i) in settingList" :key="i">
              <div class="settingItem d-flex flex-row" >
                    <v-checkbox v-model="item.isCheck" label="" class="checkbox"></v-checkbox>
                    <div class="clickItem d-flex flex-column mt-lg-3 ml-lg-2">
                        <div class="value">{{item.value}}</div>
                        <div class="valueDes">{{item.valueDes}}</div>
                    </div>
              </div>  
         </div>
          </div>
         <div class="footer box">
        <button class="sub" submit @click="subCommit">
          {{ $t("myInfoFormSave") }}
        </button>
          </div>
        </div>
        <div class="right ml-10 mt-1" v-else>
              <div class="top_tab ">
             <span class="btn mr-16" :class="{ btn2: ownerClick }" @click="mode_click('NFTs')"
            >{{ $t("NFTs") }}</span
            > 
             <span class="btn mr-16" :class="{ btn2: soldClick }" @click="mode_click('Collections')"
            >{{ $t("Collections") }}</span
          > 
            <span class="btn " :class="{ btn2: folderClick }"  @click="mode_click('Creators')"
            >{{ $t("Creators") }}</span
          >       
        </div>
        <div class="createlists" v-if="clickMode == 'Creators'"  ref="group" @scroll="groupScroll" @mouseover="groupMouseover">
            <div class="cont d-flex flex-lg-row flex-wrap">
      <div
        class="indexfan d-flex flex-column"
        v-for="(item, index) in nftList"
        :key="index"
        
      >
        <template>
            <div class="ava d-flex justify-center mt-4 mt-lg-6 mb-2 mb-lg-2">
            <Avatar
                style="margin: 0 auto"
                :size="109"
                v-if="!isMobile"
                :hash="item.profilePhoto"
                :grade="item.grade"
                imgType="especially"
                showAvatarInfo
                :did="item.address"
            />
            </div>
            <div class="name">{{item.userName }}</div>
            <!-- <div class="num">{{ $t("avatarlistWork") }} {{ item.workCount }}</div> -->
             <div class="dialodup5" >
                
            </div>
            <button class="drawbtn" @click.stop="AbanBtn(item)"  > {{ $t("Unhide") }}</button>
        </template>
      </div>
    </div>
        </div>
         <div class="nftlists mt-7" ref="group" @scroll="groupScroll" @mouseover="groupMouseover" v-else>
            <div class="nftList" v-for="(item, i) in nftList" :key="i">
              <div class="nftItem d-flex justify-space-between" >
                  <div class="itemleft d-flex flex-row">
                       <img class="img" :src="getImageIpfs(item.imgUrl)" />
                        <div class="name">{{item.name}}</div>
                  </div>
                
                      <button class="subUnhide" submit @click="subUnhide(item)">
                     {{ $t("Unhide") }}
                </button>
               
                   
              </div>
       
         </div>
            <loading :isShowLoading="isShowLoading" style="margin-left: auto"></loading>
          </div>

        </div>
          
       
      </div>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import api from "@/api";
import Loading from "@/components/loading.vue";
import Avatar from "@/components/avatar/index.vue";
export default {
  components: {Loading,Avatar },
  data: function () {
    return {
        isloadList:false,
        isShowLoading: false,
        pageNumber: 1,
        pageSize: 10,
        totalPage: 0,
        isClickedSet:true,
        isHield:false,
        folderClick:false,
        soldClick : false,
        ownerClick:true,
        address:'',
        selectModel:'mySetting',
        selectSettingList:[],
        isShowTip:[],
        nftList:[],
        clickMode:"NFTs",
        settingList:[
            {
                key:0,
                value: this.$t('Set_Submit_tatus'),
                valueDes:this.$t('Set_Submit_tatus_des'),
                isCheck:true
            },
            {
                key:1,
                value: this.$t('Set_Operation_result'),
                valueDes:this.$t('Set_Operation_result_des'),
                isCheck:true
            },
             {
                key:2,
                value: this.$t('Set_Sales_result'),
                valueDes:this.$t('Set_Sales_result_des'),
                isCheck:true
            },
            {
                key:3,
                value: this.$t('Set_Purchase_result'),
                valueDes:this.$t('Set_Purchase_result_des'),
                isCheck:true
            },
             {
                key:4,
                value: this.$t('Set_Bid_changes'),
                valueDes:this.$t('Set_Bid_changes_des'),
                isCheck:true
            },
            {
                key:5,
                value: this.$t('Set_Receive_NFTs'),
                valueDes:this.$t('Set_Receive_NFTs_des'),
                isCheck:true
            },
             {
                key:6,
                value: this.$t('Set_Prize_Results'),
                valueDes:this.$t('Set_Prize_Results_des'),
                isCheck:true
            },
            {
                key:7,
               value: this.$t('Set_New_followers'),
                valueDes:this.$t('Set_New_followers_des'),
                isCheck:true
            },
             {
                key:8,
               value: this.$t('Set_New_reminder'),
                valueDes:this.$t('Set_New_reminder_des'),
                isCheck:true
            },
        
           

        ],
     
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
      this.address = this.$store.state.did.substr(0, 6) + "..." + this.$store.state.did.substr(-6);
      this.initDate();
      window.eventBus.$on("LangChange", this.onLangChange);
   
  },
 
  methods: {
      clicked(model){
          this.selectModel = model
          if(model == 'mySetting'){
              this.isClickedSet = true;
              this.isHield = false
          }else{
             this.isClickedSet = false;
              this.isHield = true 
              this.nftList =[],
              this.pageNumber = 1
                 this.getNftsList(this.clickMode);
          }
      },

async subUnhide(item){
  let params = {
    		authenticationAddress:this.$store.state.did
  }
  if(item.contractAddress){
     params. blockNftAddress = item.contractAddress
  }else{
    if(item.contractType == 'COLLECTION'){
         params. blockContractCollectionId = item.contractCollectionId
    }else{
         params.blockContractCollectionId = item.contractCollectionId
      
    }
  }
  let res = await api.home.releaseNfts(params)
				if(res.success){
           
					this.$toast("success", this.$t("success")).then(() => {
             this.nftList=[];
              this.pageNumber = 1;
              this.getNftsList(this.clickMode);
        });
				}else{
					 this.$toast("error", "Activate faild")
				}
},
      subCommit(){
          let timestamp = new Date().valueOf();
          this.settingList.forEach((v)=>{
              v.timestamp = timestamp
          })
             
          this.selectSettingList = this.settingList.filter(v=>v.isCheck && v.key != 0)
          this.isShowTip = this.settingList.filter(v=>v.key == 0)
          localStorage.setItem("IRIS_isShowTip_"+this.$store.state.did,this.isShowTip[0].isCheck)
          localStorage.setItem('IRIS_SettingSelectList_'+this.$store.state.did,JSON.stringify(this.selectSettingList))
            localStorage.setItem('IRIS_SettingList_'+this.$store.state.did,JSON.stringify(this.settingList))
            this.$toast("success", this.$t("save_success"));

      },
          mode_click(mode) {
              
              this.showStatus(mode);
          },
         async AbanBtn(item){
              let params = {
                authenticationAddress:this.$store.state.did,
                blockOwner:item.did
             }
          let res = await api.home.releaseNfts(params)
				if(res.success){
						this.$toast("success", this.$t("success")).then(() => {
               this.nftList=[];
                this.pageNumber = 1;
              this.getNftsList(this.clickMode);
        });
				}else{
					 this.$toast("error", "Activate faild")
				}

          },
            showStatus(mode){
                this.clickMode = mode
                if(mode == "NFTs"){
                    this.nftList=[];
                     this.pageNumber = 1;
                     this.getNftsList(mode);
                    localStorage.setItem("collection_card", 1);
                    this.collectionClick = false;
                    this.soldClick = false;
                    this.ownerClick = true;
                    this.folderClick = false;
                }else if(mode == "Collections"){
                     this.nftList=[];
                     this.pageNumber = 1;
                     this.getNftsList(mode);
                    localStorage.setItem("collection_card", 2);
                    this.collectionClick = false;
                    this.soldClick = true;
                    this.ownerClick = false;
                        this.folderClick = false;
                }else if(mode == "Creators"){
                   this.nftList=[];
                     this.pageNumber = 1;
                    localStorage.setItem("collection_card", 3);
                    this.collectionClick = true;
                    this.soldClick = false;
                    this.ownerClick = false;
                    this.folderClick = true;
                     this.getNftsList(mode);
                }
    },
   async  getNftsList(mode){
        this.isShowLoading = true;
       let params = {
        authenticationAddress: this.$store.state.did,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
     
      };
           if( this.isloadList){
        return
      }
         this.isloadList = true
         let res;
         if(mode == 'NFTs'){
            res = await api.home.hideNftsList(params);
         }else if(mode == 'Collections'){
             res = await api.home.hideCollectList(params);
         }else{
            res = await api.home.hideUserList(params);
         }
      if(res.data.list && res.data.list.length > 0){
          this.nftList = [...this.nftList, ...res.data.list];
      }
       
        this.totalPage = res.data.totalPage;
         this.isShowLoading = false
            this.isloadList = false
    },
       initDate(){
       this.settingList = [
            {
                key:0,
                value: this.$t('Set_Submit_tatus'),
                valueDes:this.$t('Set_Submit_tatus_des'),
              
            },
            {
                key:1,
                value: this.$t('Set_Operation_result'),
                valueDes:this.$t('Set_Operation_result_des'),
         
            },
             {
                key:2,
                value: this.$t('Set_Sales_result'),
                valueDes:this.$t('Set_Sales_result_des'),
          
            },
            {
                key:3,
                value: this.$t('Set_Purchase_result'),
                valueDes:this.$t('Set_Purchase_result_des'),
             
            },
             {
                key:4,
                value: this.$t('Set_Bid_changes'),
                valueDes:this.$t('Set_Bid_changes_des'),
             
            },
            {
                key:5,
                value: this.$t('Set_Receive_NFTs'),
                valueDes:this.$t('Set_Receive_NFTs_des'),
    
            },
             {
                key:6,
                value: this.$t('Set_Prize_Results'),
                valueDes:this.$t('Set_Prize_Results_des'),
       
            },
            {
                key:7,
               value: this.$t('Set_New_followers'),
                valueDes:this.$t('Set_New_followers_des'),
          
            },
             {
                key:8,
               value: this.$t('Set_New_reminder'),
                valueDes:this.$t('Set_New_reminder_des'),
      
            },

        
        ] 
          let StorageSettingList = JSON.parse(localStorage.getItem('IRIS_SettingList_'+this.$store.state.did)) 
      if(StorageSettingList){
          for (let i = 0; i < StorageSettingList.length; i++) {
             this.settingList[i].isCheck = StorageSettingList[i].isCheck
              
          }
             localStorage.setItem('IRIS_SettingList_'+this.$store.state.did,JSON.stringify(this.settingList))

   
      }else{
          this.settingList.forEach(e => {
              e.isCheck = true
             
         });
      }
    
  },
   groupScroll(e) {
       console.log("wxk  --- ",e)
      let ele = e.srcElement ? e.srcElement : e.target;
      if ((ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 1)
          && this.pageNumber < this.totalPage) {
        ++this.pageNumber;
        this.getNftsList(this.clickMode);
      }
    },
    groupMouseover() {
      this.$refs.group.focus();
    },
  onLangChange(){
      this.initDate();
       
      }
   
  },
 
};
</script>

<style lang="scss" scoped>
.contain {
     margin: 0;
  padding: 0;
   .setting{
       margin: 0px 40px;
       .left{
      position: sticky;
    top: 105px;
    height: 20px;
    background-color: white;
    margin-top: -10px !important;
    .title{
        cursor: pointer;
        min-width: 223px;
        height: 40px;
        background-color: #f0f0f0;
        border-radius: 20px;
        text-align: center;
        font-family: Helvetica;
        font-size: 16px!important;
        font-weight: bold;
        font-stretch: normal;
        line-height: 38px;
        letter-spacing: 0px;
        color: #270645;
    }
    .clickedItem{
        background-color: #270645;
        	color: #ffffff;
    }

       }
       .right{
            .top_tab {
                width: 950px;
        .btn {
          height: 25px;
          font-family:Helvetica;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: 0px;
          color: #766983;
          cursor:pointer;
        }
        .btn2 {
          height: 25px;
          font-family:Helvetica;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: 0px;
         color: #270645;
          border-bottom: 3px solid #270645;
          padding-bottom: 8px;
        }
      }
              .setTitle{
            font-family: Helvetica;
            font-size: 28px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
       }
        .description{
            font-family: Helvetica;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
        }
        .list{
        .settingList{
            .settingItem{
            width: 750px;
            height: 73px;
            background-color: #ffffff;
            border: solid 2px #e3e3e3;
            margin-top:-2px;
            .checkbox{
                margin-left: 30px;
                
            }
            .clickItem{
                .value{
                font-family: Helvetica;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #270645;
                }
                .valueDes{
                    font-family: Helvetica;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    letter-spacing: 0px;
                    color: #766983;
                }
                

            }
            }
            
        }
        }
        .nftlists{
            overflow-y: auto;
            height: 73vh;
            max-height: 73vh;
            margin-bottom: 100px;
            .nftList{
                .nftItem{
                    	width: 935px;
                        height: 105px;
                        background-color: #ffffff;
                        border-radius: 5px;
                        border: solid 1px #e3e3e3;
                        text-align: center;
                        align-items: center;
                        margin-top: 10px;
                        .itemleft{
                            img{
                        width: 75px;
                        height: 75px;
                        margin-left: 15px;
                        object-fit: cover;
                         }
                         .name{
                             font-family: Helvetica;
                                font-size: 15px;
                                font-weight: bold;
                                font-stretch: normal;
                                letter-spacing: 0px;
                                color: #270645;
                             margin-top:20px;
                             margin-left: 20px;
                         }
                        }
                    
                            .subUnhide{
                            margin-right: 20px;
                           width: 85px;
                            height: 29px;
                            background-color: #7800f4;
                            border-radius: 14px;
                            font-family: Helvetica;
                            font-size: 13px;
                            font-weight: normal;
                            font-stretch: normal;
                            letter-spacing: 0px;
                            color: #ffffff;
                            }     
                }
            }
        }
        .createlists{
             overflow-y: auto;
            height: 73vh;
            max-height: 73vh;
            .cont {
    max-width: 1216px;
    margin: 0 0 48px;
    .indexfan {
      cursor: pointer;
      width: 160px;
      height: 190px;
      background-blend-mode: normal, normal;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
      border-radius: 5px;
      margin-right: 26px;
      margin-top: 20px;
      position:relative;

      &:nth-child(5n) {
        margin-right: 0;
      }
      .dialodup5 {
        width: 160px;
        height: 190px;
        background-color: #7800f4;
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        z-index: 300;
      /* z-index: 10; */
      
   }
   .drawbtn{
         width: 85px;
        height: 30px;
        background-color: #7800f4;
        border-radius: 14px;
        position:absolute;
        left:37px;
        bottom: 17px;
        visibility: hidden;
        font-family: Helvetica;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: 0px;
        color: #ffffff;
        z-index: 400;
      }
    }
    .ava {
      width: 100%;
      opacity: 0.9;
    }
    .num {
      font-family:Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      width: 100%;
      text-align: center;
    }
    .name {
      width: 100%;
      text-align: center;
      font-family:Helvetica;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
   
    .indexfan:hover  .dialodup5 {
        visibility: visible;
        background-color: #270645;
        border-radius: 5px;
        border: solid 1px #cccccc;
        opacity: 0.7;
    }
    .indexfan:hover .drawbtn {
        visibility: visible;
    }
  }
        }

                .footer {
                    margin: 30px 155px 80px;
                    .sub {
                    margin: 0 auto;
                    width: 450px;
                    height: 51px;
                    background-color: #7800f4;
                    background-blend-mode: normal, normal;
                    border-radius: 25px;
                    opacity: 0.9;
                    font-size: 20px;
                    color: #ffffff;
                    font-weight: bold;
                    }
                }
       }
    
       
   }
   
   
   
   
 &.mobile{
    .setting{
    margin: 40px 10px;
    .left{

    }
    .right{
         .list{
        .settingList{
            .settingItem{
                width: 100%;
                .clickItem{
                .value{ 
                    font-size: 15px;
              
                }
                .valueDes{
                    font-size: 13px;
                    
                }
                

            }
            }
        }
    }
    .footer {
        margin: 30px 26px 80px;
        .sub{
            width: 280px;
        }
    }
    }
    
    }

    }
  
}
</style>
